@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
$main-accent: #cd3234;
$main-accent-highlight: #eb5c5e;
$main-black: #3f3f3f;

html {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', sans-serif;
}

button,
input,
submit {
    border: none;
}

.wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    max-width: 1116px;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;
}

header {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &.wrapper {
        margin-top: 1rem;
    }

    img {
        max-width: 100%;
    }
}

a.button {
    display: inline-block;
}

.button {
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    font-weight: 600;

    &.small {
        padding: 0.2rem 0.5rem;
        font-size: 1rem;

        &.round {
            border-radius: 1rem;
        }
    }

    &.big {
        height: auto;
        font-size: 2rem;
        line-height: 1.8rem;
        padding: 0.8rem 2rem 1rem;

        &.round {
            border-radius: 1rem;
        }
    }

    &.button-contact {
        background-color: $main-accent;
        color: white;
        transition: background-color 0.2s, transform 0.2s, box-shadow 0.1s;

        &:hover {
            background-color: $main-accent-highlight;
            transform: scale(1.01);
            box-shadow: 2px 4px 4px $main-black;
        }
    }
}

.waves {
    background: rgb(222,85,87);
    background: linear-gradient(180deg, rgba(222,85,87,1) 0%, rgba(205,50,52,1) 100%);
    position: relative;
    margin-top: 114px;

    .waves-wrapper-mobile {
        display: none;
    }

    .waves-wrapper,
    .waves-wrapper2,
    .waves-wrapper3 {
        position: absolute;
        height: 111px;
        width: 100%;
        left: 0;
        top: -111px;
        z-index: 4;
        background: url("./wrapper1.png");
        background-repeat: repeat-x;
        background-position: 0;
    }

    .waves-wrapper2 {
        background-position: 10%;
        z-index: 3;
        background: url("./wrapper2.png");
    }

    .waves-wrapper3 {
        background-position: 20%;
        height: 103px;
        top: -103px;
        z-index: 2;
        background: url("./wrapper3.png");
    }
}

.coaching,
.intresse,
.products {
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;

    h1 {
        font-size: 3rem;
    }
}

.products h1 {
    margin-bottom: 4rem;
}

.coaching {
    padding: 8rem 0;

    h1.main-title {
        text-align: center;
        font-size: 5rem;
        margin: 0;
    }

    .coaching-block {
        width: 50%;
        box-sizing: border-box;
        float: left;
    }

    .main-box {
        width: 560px;
        max-width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;

        img {
            max-width: 75px;
            height: auto;
            margin-right: 1.5rem;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.4rem;
        }
    }
}

.intresse {
    border-top: 0.4rem solid $main-accent;
}

.products {
    color: $main-black;
}

.products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

.product {
    flex: 1 0 21%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    transition: transform 0.5s cubic-bezier(0.44, 2.54, 0.81, 0.02);

    &-title {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    a {
        text-decoration: none;
        color: $main-black;
        text-align: center;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
}

footer {
    .adress {
        background-color: $main-accent;
        color: white;
        padding: 2rem;

        ul {
            text-decoration: none;
            list-style: none;
            text-align: center;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 1rem;

                &.bold {
                    font-weight: 600;
                }
            }
        }
    }

    .footer-nav {
        ul {
            text-decoration: none;
            list-style: none;
            text-align: center;

            li {
                display: inline-block;
                position: relative;
                padding-right: 1rem;
                margin-right: 1rem;

                a {
                    text-decoration: none;
                    color: $main-black;
                    border-bottom: 2px solid transparent;
                    transition: border-bottom 0.5s;

                    &:hover {
                        border-bottom: 2px solid $main-black;
                    }
                }

                &:after {
                    content: '/';
                    position: absolute;
                    right: 0;
                }

                &:last-child {
                    margin-right: 0;
                    padding-right: 0;

                    &:after {
                        content: '';
                    }
                }
            }
        }
    }
}

.intresse-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: $main-accent;

    h1 {
        display: inline;
    }

    a.button {
        margin-left: 1rem;
    }
}

@media only screen and (max-width: 825px) {
    .coaching {

        .coaching-block {
            width: 100%;
            box-sizing: border-box;
            float: left;
        }
    }
}

@media only screen and (max-width: 690px) {
    .coaching {
        h1.main-title {
            font-size: 5rem;
        }
    }
}
@media only screen and (max-width: 690px) {
    .coaching {
        h1.main-title {
            font-size: 5rem;
        }
    }

    .product {
        flex-basis: 50%;
    }
}
@media only screen and (max-width: 435px) {

    .waves {
        margin-top: 50px;

        .waves-wrapper-mobile {
            background: none;
    display: block;
    position: absolute;
    height: 50px;
    width: 100%;
    top: -50px;
    background-size: cover;
    background-repeat: repeat-x;
    z-index: 4;
            background-image: url("./wrapper1.png");
        }
    } 
    .waves-wrappers {
        display: none;
    }


    .coaching {

        padding: 2rem 0;

        .main-box {
            flex-direction: column;
            align-items: center;
        }
    }

    .intresse,
    .products {
        padding-top: 0;
    }

    .coaching p {
        max-width: none;
    }

    .intresse-wrapper {
        a.button {
            flex: 1;
            flex-basis: 100%;
            margin-left: 0;
        }
    }

    footer {
        .adress {
            padding: 1rem;

            & li:last-child {
                margin-bottom: 0;
            }
        }

        .footer-nav {
            ul {
                padding: 0;
                margin: 1rem 0;

                li {
                    padding: 0;
                    margin: 0 0 0.5rem;
                    display: block;

                    &:after {
                        content: '';
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 395px) {
    .coaching {
        h1.main-title {
            font-size: 4rem;
        }
    }
}
@media only screen and (max-width: 385px) {
    header {
        margin: 0 auto;

        a {
            flex-basis: 25%;
            margin-top: 1rem;
        }
    }

    .products h1 {
        margin-bottom: 2rem;
    }


    .product {
        flex-basis: 100%;
    }

    .coaching,
    .intresse,
    .products {
        h1 {
            font-size: 2rem;
        }
    }
}

@media only screen and (max-width: 314px) {
    .coaching {
        h1.main-title {
            font-size: 3rem;
        }
    }
}
